import { useEffect, useMemo } from 'react';
import Cookies from 'universal-cookie';
import { createGlobalState, useCookie } from 'react-use';
import { Case, OpenCase as OpenCaseTypes } from '~types';
const cookies = new Cookies();
type StatusGameTypes = [number, (state: number) => void];
type FastModeTypes = [boolean, (state: boolean) => void];

type TSliderStyleProps = {
  timeout: number;
};

const useStatusGameValue = createGlobalState<number>(0);
const useGameCountValue = createGlobalState<number>(1);

const useGamesValue = createGlobalState<OpenCaseTypes[]>([]);
export const useSliderOpenStyle = createGlobalState({
  opacity: 0,
});
export const useFastModeValue = createGlobalState<boolean>(
  cookies.get('useFastMode') === 'true',
);
export const useFastMode = (): FastModeTypes => {
  const [, setCookie] = useCookie('useFastMode');
  const [isFastGame, setFastGame] = useFastModeValue();

  const onChange = (value: boolean) => {
    setFastGame(value);
    setCookie(String(value));
  };

  return [isFastGame, onChange];
};

export const useSliderStyle = createGlobalState<TSliderStyleProps>(null);

export const useGames = () => useGamesValue();

export const useStatusGame = (): StatusGameTypes => {
  const [isGame, setStatus] = useStatusGameValue();
  const [, setGames] = useGames();

  useEffect(() => {
    if (isGame === 0) {
      setGames([]);
    }
  }, [isGame, setGames]);

  return [isGame, setStatus];
};

export const useGameCount = (): StatusGameTypes => {
  const [isGame, setStatus] = useGameCountValue();

  return [isGame, setStatus];
};

const calcCount = (countOpen: number) => {
  return countOpen < 0 ? 0 : countOpen;
};

export const useCalceOfPossibleFreeOpenPrice = (
  getCase: Case,
  userOpenForFree: number,
  countCaseOpen: number,
) => {
  const freeCountOpen = useMemo(() => {
    if (getCase?.needToOpenForFree === 0) {
      return getCase?.userFreeOpen;
    }

    return calcCount(
      Math.floor(
        (userOpenForFree + countCaseOpen) / (getCase?.needToOpenForFree + 1),
      ) -
        Math.floor(userOpenForFree / (getCase?.needToOpenForFree + 1)) +
        getCase?.userFreeOpen,
    );
  }, [
    countCaseOpen,
    getCase?.needToOpenForFree,
    getCase?.userFreeOpen,
    userOpenForFree,
  ]);

  const price = useMemo(
    () => getCase?.price * calcCount(countCaseOpen - freeCountOpen),
    [countCaseOpen, freeCountOpen, getCase?.price],
  );

  const priceOld = useMemo(
    () => getCase?.priceOld * countCaseOpen,
    [countCaseOpen, getCase?.priceOld],
  );

  const battlePassCoins = useMemo(
    () => getCase?.battlePassCoins * calcCount(countCaseOpen - freeCountOpen),
    [countCaseOpen, freeCountOpen, getCase?.battlePassCoins],
  );

  return [price, priceOld, freeCountOpen, battlePassCoins];
};
