import { useState, memo, FunctionComponent, ReactNode, useMemo } from 'react';
import { useInterval } from 'react-use';
import { Case as CaseType } from '~types';
import dayjs from 'dayjs';
import { useCalceOfPossibleFreeOpenPrice } from '~frontend/components/play/hooks';

interface DisableCaseProps {
  getCase: CaseType;
  classNames: string;
  children?: ReactNode;
}

const DisableCase: FunctionComponent<DisableCaseProps> = ({
  classNames,
  getCase,
  children,
}) => {
  const [timeLeft, setTime] = useState(null);

  useInterval(
    () => {
      setTime(dayjs(getCase?.getDateEnd).diff(dayjs(), 'milliseconds'));
    },
    timeLeft === 0 || getCase?.type !== 2 ? null : 1000,
  );

  const isDisable = useMemo(() => {
    if (getCase?.type === 2 && timeLeft < 0) {
      return true;
    } else if (getCase?.type == 1 && getCase?.getLeft === 0) {
      return true;
    }

    return getCase?.isDisable;
  }, [getCase?.getLeft, getCase?.isDisable, getCase?.type, timeLeft]);

  const [price] = useCalceOfPossibleFreeOpenPrice(
    getCase,
    getCase?.userOpenForFree,
    1,
  );

  return (
    <div
      data-rar={price === 0 ? '06c50c' : getCase?.color}
      className={`${classNames} ${isDisable && 'disable'} ${
        price === 0 && 'light'
      }`}
      data-type={getCase?.type}
    >
      {children}
    </div>
  );
};

export default memo(DisableCase);
